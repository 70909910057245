import React from "react";
import profits from "../../../../../assets/images/net-profits.png";
import { Report } from "./ReportStatics.styles";

function ReportStatics(data) {
  // format money
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <Report>
      <Report.Profit>
        <Report.ContainerImgProfit>
          <Report.ImgProfit src={profits} />
        </Report.ContainerImgProfit>
        <Report.ContentTextRevenue>
          <Report.TextSubProfit>Lợi nhuận ròng</Report.TextSubProfit>
          <Report.TextProfit>{formatter.format(Number(data.data?.totalWinProfit)?.toFixed(1))}</Report.TextProfit>
        </Report.ContentTextRevenue>
      </Report.Profit>

      <Report.TransactionSummary>
        <Report.TextSubSummary>Tóm tắt giao dịch</Report.TextSubSummary>
        <Report.TextSummary>Lên</Report.TextSummary>
        <Report.ContainerBarSummary>
          <Report.StatusBarWin style={{ width: `${data.data?.percentBuy}%` }}>
            {Number(data.data?.percentBuy).toFixed(2)}%
          </Report.StatusBarWin>
        </Report.ContainerBarSummary>
        <Report.TextSummary>Xuống</Report.TextSummary>
        <Report.ContainerBarSummary>
          <Report.StatusBarLose style={{ width: `${data.data?.percentSell}%` }}>
            {Number(data.data?.percentSell)?.toFixed(2)}%
          </Report.StatusBarLose>
        </Report.ContainerBarSummary>
      </Report.TransactionSummary>
    </Report>
  );
}

export default ReportStatics;
