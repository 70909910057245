import AxiosClient from "./axiosClient";

const API_ENDPOINT = "api/transaction/";
// tạo lệnh rút tiền
export const withdrawMoney = (data) => {
    return AxiosClient.post(API_ENDPOINT + 'user/withdraw', data)
}
// lịch sử nạp rút
export const historyTranSation = (params) => {
    return AxiosClient.get(API_ENDPOINT + `history?order=${params.order}&page=${params.page}&take=${params.take}`)
}
// lịch sử nạp 
export const historyNap = () => {
    return AxiosClient.get(API_ENDPOINT + `manager/bank`)
}
export const putHistoryNap = (data) => {
    return AxiosClient.post(API_ENDPOINT + `user/deposit`, data)
}