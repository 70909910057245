import tw from 'tailwind-styled-components'

export const BoardWrapper = tw.div`
    ml-[2px]
    h-full
    mr-[5px]
    max-md:ml-0
`
BoardWrapper.Header = tw.div`
    flex
    items-center
    text-[#fff]
    text-[18px]
    px-[15px]
    h-header

    max-md:hidden
`
BoardWrapper.StartIcon = tw.img`
    mr-[10px]
    w-[30px]
    w-[30px]
`
BoardWrapper.market = tw.div`
    bg-[#02142b]
    flex
    flex-col
    text-[#93B2EC]
    rounded-[10px]
    border-[1px]
    border-solid
    border-[#465991]
    h-[calc(100%/2)]
    bg-[#02142b]

    max-md:border-[0px]
    max-md:h-[400px]
`
BoardWrapper.Title = tw.span`
    text-[17px]
    font-[600]
    px-[15px]
    py-[14px]

    max-md:hidden
`
BoardWrapper.Percent = tw.div`
    flex
    items-center
    gap-[5px]
    justify-center
`
BoardWrapper.Number = tw.span`
    text-[#23FF49]
    font-[600]
`
BoardWrapper.Transaction = tw.div`
    bg-[#131F46]
    flex
    flex-col
    text-[#93B2EC]
    rounded-[10px]
    border-[1px]
    border-solid
    border-[#465991]
    h-[calc(100%/2)]
    bg-[#02142b]

    max-md:border-[0px]
    max-md:h-[400px]
`
BoardWrapper.Table = tw.table`
    w-[250px]
    mt-[5px]
    border-separate
    border-spacing-y-[5px]

    max-md:w-full
`
BoardWrapper.Th = tw.th`
    text-center
    text-[#8FA8D8]
    text-[14px]
    font-[400]

    max-md:text-[16px]
`
BoardWrapper.Td = tw.td`
    text-center
    text-[#B9D2FF]
    text-[14px]
    font-[400]
    py-[5px]
`
BoardWrapper.TrHead = tw.tr`
    h-[35px]
    border-t-[1px]
    border-b-[1px]
    border-solid
    border-[#465991]
    rounded-[10px]
    bg-[#263B63]
`
BoardWrapper.Tr = tw.tr`
`
BoardWrapper.BoxMoney = tw.div`
    flex
    gap-[5px]
    items-center
    justify-center
    py-[5px]
    border-[1px]
    border-[#23FF49]
    border-solid
    rounded-[3px]
    mr-[5px]
`
BoardWrapper.Arrow = tw.img`
    w-[9px]
    h-[13px]
`
