import React from 'react'
import Board from './components/board/Board'
import BoardControl from './components/boardControl/BoardControl'
import Chart from './components/chart/Chart'
import { Dash } from './DashBoard.styles'

function DashBoard() {
    return (
        <Dash>
            <Dash.Center>
                <Chart />
            </Dash.Center>
            <Dash.Left>
                <Board />
            </Dash.Left>

            <Dash.Right>
                <BoardControl />
            </Dash.Right>
        </Dash>
    )
}

export default DashBoard