import tw from "tailwind-styled-components";

export const Stats = tw.div`
    w-full 
    h-full
    flex
    flex-col 
    bg-[#02142b]
    md:px-[80px]
    overflow-auto

    max-md:flex-col
    max-md:h-full
`;
Stats.Body = tw.div`
    w-full
    h-[calc(100%-theme(spacing.header))]
    flex
    flex-col

    max-md:flex-col
    max-md: px-[15px]
    max-md:items-center
`;
Stats.TransactionStatistics = tw.div`
    w-full
    flex
    flex-row
    mt-[50px]

    max-md:w-full
    max-md:flex-col
    max-md:mt-[20px]
`;
