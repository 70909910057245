import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    order: 'DESC',
    page: '1',
    take: '8',
    search: ''
}

const historyTranSationSlice = createSlice({
    name: 'historyTranslation',
    initialState,
    reducers: {
        ChangePage: (state, action) => {
            state.page = action.payload;
        }
    }
})

export const { ChangePage } = historyTranSationSlice.actions;
export default historyTranSationSlice.reducer