import tw from "tailwind-styled-components";

export const Report = tw.div`
    w-4/12 
    flex 
    flex-col 
    items-end

    max-md:w-full
    max-md:mb-[50px]
    max-md:order-first
`;

Report.Profit = tw.div`
    w-10/12 
    flex flex-row 
    aspect-[25/10] 
    bg-[#20244C] 
    rounded-lg 
    p-[20px] 

    max-md:w-full
`;
Report.ContainerImgProfit = tw.div`
    w-2/5 
    h-full 
    flex 
    items-center
`;
Report.ImgProfit = tw.img`
    w-10/12 
    aspect-square
`;
Report.ContentTextRevenue = tw.div`
    flex flex-col 
    item-start 
    justify-center 
    px-[10px]
`;
Report.TextSubProfit = tw.span`
    text-[#93B2EC]
    text-lg
    pb-[10px]

    max-md:text-lg
`;
Report.TextProfit = tw.span`
    text-[#FFFFFF] 
    text-2xl 
    font-semibold

    max-md:text-2xl
`;

Report.TransactionSummary = tw.div`
    w-10/12 
    flex 
    flex-col 
    aspect-[25/10] 
    bg-[#20244C] 
    rounded-lg 
    p-[20px] 
    mt-[20px]

    max-md:w-full
    max-md:mt-[30px]
`;
Report.TextSubSummary = tw.span`
    flex 
    text-lg
    font-medium 
    text-[#FFFFFF]

    max-md:text-xl
`;
Report.TextSummary = tw.span`
    flex 
    text-lg 
    font-normal 
    text-[#B9D2FF]
    mt-[10px] 
    mb-[3px]
`;
Report.ContainerBarSummary = tw.div`
    w-full
    flex 
    bg-[#707070] 
    h-[35px] 
    items-center 
    rounded-sm

    max-md:h-[30px]
`;
Report.StatusBarWin = tw.div`
    w-full 
    bg-[#23FF49] 
    h-full 
    items-center 
    rounded
    text-md 
    font-medium 
    text-[#000000] 
    pl-[10px]
    flex
    items-center
`;
Report.StatusBarLose = tw.div`
    w-full 
    bg-[#FF6E75] 
    h-full 
    items-center 
    rounded
    text-md 
    font-medium 
    text-[#000000] 
    pl-[10px]
    flex
    items-center
`;
