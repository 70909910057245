import tw from 'tailwind-styled-components'

export const Dash = tw.div`
    flex
    bg-[#02142b]
    h-full
    overflow-y-auto
    pt-[5px]

    max-md:flex-col
    max-md:h-full
`
Dash.Left = tw.div`
    max-md:order-last
`
Dash.Center = tw.div`
    flex-1


`
Dash.Right = tw.div`
`
Dash.LeftBody = tw.div`
    flex
`
