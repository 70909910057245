import Home from "../screens/home/Home";
import { Routes, Route } from 'react-router-dom'
import DashBoard from "../screens/tab/dashboard/DashBoard";
import Wallet from "../screens/tab/wallet/Wallet";
import Account from "../screens/tab/account/Account";
import Statistics from './../screens/tab/statistics/Statistics';
import Ladi from "../screens/ladi/Ladi";

const ProtectedRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} >
                <Route path="/dashboard" element={<DashBoard />} />
                <Route path="/" element={<Ladi />} />
                <Route path="/statics" element={<Statistics />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/account" element={<Account />} />
            </Route>
        </Routes>
    )
}

export default ProtectedRoutes;