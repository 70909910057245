import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: null
}

const resetPass = createSlice({
    name: 'resetPass',
    initialState,
    reducers: {
        confirmMail: (state, action) => {
            state.email = action.payload
        }
    }
})

export const { confirmMail } = resetPass.actions;
export default resetPass.reducer;