import tw from 'tailwind-styled-components'

export const ListComponent = tw.div`
    flex flex1
    flex-row
    w-full
    justify-between
    justify-items-start
    text-[#B9D2FF]
    text-md
    font-light
    py-[5px]
    mt-[10px]
`
ListComponent.TextNumber = tw.span`
    w-1/12
    flex
    justify-start

    max-md:w-1/12
`
ListComponent.TextDate = tw.span`
    w-2/12
    flex
    flex1
    justify-start

    max-md:w-3/12
    max-md:pl-[20px]
`
ListComponent.TextTrade = tw.span`
    w-2/12
    flex
    flex1
    justify-start

    max-md:w-3/12
    max-md:justify-center
`
ListComponent.TextProfitBuy = tw.span`
    w-2/12
    flex flex1
    justify-start
    text-[#23FF49]

    max-md:w-3/12
`
ListComponent.TextProfitSell = tw.span`
    w-2/12
    flex flex1
    justify-start
    text-[#FF6E75]

    max-md:w-3/12
`
ListComponent.Invest = tw.span`
    w-1/12
    flex
    justify-end

    max-md:w-2/12
`
