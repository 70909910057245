import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: '5',
    count: ''
}

const value = createSlice({
    name: 'value',
    initialState,
    reducers: {
        setValue: (state, action) => {
            state.value = action.payload;
        },
        SetCount: (state, action) => {
            state.count = action.payload;
        }
    }
})

export const { setValue, SetCount } = value.actions;
export default value.reducer