const LOCAL_STORAGE_AUTH_KEY = "LOCAL_STORAGE_AUTH_BO";

export const setToken = (token) => {
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, token);
};
export const getToken = () => {
    const jwtToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
    return jwtToken || null;
};
export const setRefeshToken = (token) => {
    localStorage.setItem('RESFESH_TOKEN_BO', token);
};
export const getRefeshToken = () => {
    const refeshToken = localStorage.getItem('RESFESH_TOKEN_BO');
    return refeshToken || null;
};
export const removeToken = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
};
