import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: ''
}

const nenSlice = createSlice({
    name: 'nen',
    initialState,
    reducers: {
        setvalueNen: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setvalueNen } = nenSlice.actions
export default nenSlice.reducer