import tw from "tailwind-styled-components";

export const RateStats = tw.div`
    w-full
`;
RateStats.SubTitle = tw.h2`
    text-2xl
    font-medium
    text-[#B9D2FF]

    max-md:hidden
`;
RateStats.Content = tw.div`
    md:w-8/12 
    flex 
    flex-row 
    justify-items-start
    font-light 
    text-lg 
    text-[#B9D2FF] 
    mt-[20px] 

    mb-[12px] 
    2xl:text-xl

    max-md:w-full 
    max-md:text-xl
    max-md:my-[20px] 
`;
RateStats.ContentWin = tw.div`
    md:w-3/12 
    flex 
    flex-row 
    items-end 
    justify-start

    max-md:w-1/2
`;
RateStats.SpaceDiv = tw.div`
    md:w-[5px]

    max-md: w-[0px]
`;
RateStats.ContentLose = tw.div`
    w-3/12 
    flex 
    flex-row 
    items-end 
    justify-start

    max-md:w-6/12 
`;
RateStats.TextSub = tw.span`
    text-xl
    opacity-70

    max-md:text-xl
`;
RateStats.TextRateWin = tw.span`
    text-[#23FF49] 
    font-semibold 
    text-xl

    2xl:text-2xl
    max-md:text-xl
`;
RateStats.TextRateLose = tw.span`
    text-[#FF6E75] 
    font-semibold 
    text-xl 

    2xl:text-2xl
    max-md:text-xl
`;
RateStats.SysText = tw.span`
    font-medium
`;