import React, { useState } from 'react'
import { Accounts } from './Account.styles'
import logo from '../../../assets/images/logo500x500.png';
import Header from '../../../components/header/Header'
import camera from '../../../assets/images/camera.png'
import avt from '../../../assets/images/avt.png'
import { useDispatch, useSelector } from 'react-redux'
import { imgAvata } from '../../../services/walletServices'
import { getToken } from '../../../utils/localStorage'
import { ChangePassWord, UpdateUsers } from '../../../services/userServices'
import Notiflix from 'notiflix';
import { useEffect } from 'react'
import { getUserInfor } from '../../../store/auth/authThunk'
import { getUser } from '../../../services/authServices'
import { Head } from '../../../components/header/Header.styles';
import { useForm } from 'react-hook-form';

function Account() {
    const userInfo = useSelector(state => state.auth.user)
    const { getValues, handleSubmit, formState: { errors } } = useForm();
    const [id, setID] = useState();
    const [name, setName] = useState();
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');
    const [mail, setMail] = useState();
    const [phone, setPhone] = useState();
    const [Img, setImg] = useState();
    // const dispatch = useDispatch()

    const handleGetinfo = () => {
        getUser()
            .then(res => {
                setID(res.data.accountId);
                setName(res.data.fullName);
                setMail(res.data.email);
                setPhone(res.data.phone);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        handleGetinfo()
    }, [])

    imgAvata({ filename: userInfo.cardImage, token: getToken() })
        .then(res => {
            setImg(res.request.responseURL);
        })
        .catch(err => {
            console.log(err);
        })

    const handleUpdateUser = () => {
        if (password === "") {
            Notiflix.Notify.failure('Vui lòng nhập mật khẩu!');
        } else {
            if (name === "") {
                Notiflix.Notify.failure('Vui lòng nhập đúng họ và tên')
            } else {
                Notiflix.Loading.pulse()
                UpdateUsers({ 'fullName': name, "password": password })
                    .then(res => {
                        handleGetinfo()
                        Notiflix.Loading.remove()
                        Notiflix.Notify.success('Cập nhật thông tin thành công')
                    })
                    .catch(err => {
                        Notiflix.Loading.remove()
                        console.log(err);
                        if (err.response.data.message[0] == "password must be longer than or equal to 3 characters") {
                            Notiflix.Notify.failure('mật khẩu tối thiểu 3 ký tự')
                        } else if (err.response.data.message[0] == "password must be shorter than or equal to 20 characters") {
                            Notiflix.Notify.failure('Mật khẩu tối đa 20 ký tự')
                        }
                        else {
                            Notiflix.Notify.failure('Đổi mật khẩu không thành công')
                        }
                    })
            }
        }

    }

    return (
        <Accounts>
            <Header title="Tài khoản của bạn" />

            <Accounts.Body>
                <Accounts.Bodybg>
                    <Accounts.Boxavt>
                        <Accounts.avt src={Img} alt="" />
                        <Accounts.Boxcam>
                            <Accounts.Cam src={camera} />
                        </Accounts.Boxcam>
                    </Accounts.Boxavt>
                </Accounts.Bodybg>
                <div className="mt-[150px] sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit(handleUpdateUser)}>
                        <div className="w-full space-x-4 flex flex-row justify-between">
                            <div className="w-full" >
                                <label className="block text-sm font-medium leading-6 text-gray-900">ID Tài Khoản</label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={id}
                                        disabled
                                        name="name"
                                        required
                                        className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full space-x-4 flex flex-row justify-between">
                            <div className="w-full" >
                                <label className="block text-sm font-medium leading-6 text-gray-900">Họ Và Tên</label>
                                <div className="mt-2">
                                    <input
                                        name="numberPhone"
                                        required
                                        value={name}
                                        className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full space-x-4 flex flex-row justify-between">
                            <div className="w-full" >
                                <label className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                                <div className="mt-2">
                                    <input
                                        name="numberPhone"
                                        required
                                        value={mail}
                                        disabled
                                        className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full space-x-4 flex flex-row justify-between">
                            <div className="w-full" >
                                <label className="block text-sm font-medium leading-6 text-gray-900">Số Điện Thoại</label>
                                <div className="mt-2">
                                    <input
                                        name="numberPhone"
                                        required
                                        value={phone}
                                        disabled
                                        className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <hr className='border-dotted border-[1px]'></hr>
                        <div className="w-full space-x-4 flex flex-row justify-between">
                            <div className="w-1/2">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Mật khẩu</label>
                                <div className="mt-2">
                                    <input
                                        name="password"
                                        type="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="w-1/2">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Nhập lại mật khẩu</label>
                                <div className="mt-2">
                                    <input
                                        type="password"
                                        value={repassword}
                                        onChange={e => setRePassword(e.target.value)}
                                        name="rePassword"
                                        className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                        </div>
                        {repassword === password ? "" : <p className="text-[#FF0000] text-sm mt-2">Mật khẩu không trùng khớp</p>}
                        <div>
                            <button type="submit" className="flex w-full justify-center rounded-md bg-[#1457ed] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#1565f1] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Đổi Mật Khẩu</button>
                        </div>
                    </form>
                </div>
            </Accounts.Body>
        </Accounts>
    )
}

export default Account