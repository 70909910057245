import AxiosClient from "./axiosClient";

const API_ENDPOINT = 'api/trading/'

export const trading = (params) => {
    return AxiosClient.post(API_ENDPOINT + `create?positionType=${params.positionType}&amount=${params.amount}`)
}

export const cancelTrading = (id) => {
    return AxiosClient.post(API_ENDPOINT + `cancel?idTrading=${id}`)
}

export const historyTran = () => {
    return AxiosClient.get(API_ENDPOINT + `history?order=DESC&page=1&take=4`)
}

export const historyTrading = ({order = 'DESC', page, take = 5}) => {
    return AxiosClient.get(API_ENDPOINT + `history`, {params: {order: order, page: page ?? 1, take: take}})
}

export const result = () => {
    return AxiosClient.get(API_ENDPOINT + 'result')
}
