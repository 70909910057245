import React, { useEffect, useState } from 'react'
import { Loading } from 'notiflix';
import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoutes from './protectedRoutes';
import PublicRoutes from './publicRoutes';
import { getUserInfor } from '../store/auth/authThunk';
import { getRefeshToken, getToken, setRefeshToken, setToken } from '../utils/localStorage';
import { useNavigate } from 'react-router-dom'
import { refeshToken } from '../services/authServices';

function AppRoutes() {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate()

    // check user
    useEffect(() => {
        if (user) {
            navigate('/dashboard')
        }
    }, [user])

    const Routes = user && user !== {} ? ProtectedRoutes : PublicRoutes;
    // set loading
    useEffect(() => {
        if (loading) Loading.pulse();
        else Loading.remove();
    }, [loading]);

    // get user
    useEffect(() => {
        const jwtToken = getToken();

        if (jwtToken) {
            dispatch(getUserInfor());
        }
    }, []);
    // refesh token
    useEffect(() => {
        if (user) {
            setInterval(() => {
                const token = getRefeshToken()
                refeshToken(token)
                    .then(res => {  
                        setToken(res.data.accessToken)
                        setRefeshToken(res.data.refreshToken)
                    })
            }, 3600000);
        }
    }, [user])

    return !loading && <React.Fragment><Routes /></React.Fragment>;
}

export default AppRoutes