import AxiosClient from "./axiosClient";

const API_ENDPOINT = "api/user/";

export const login = (data) => {
    return AxiosClient.post(API_ENDPOINT + 'login', data)
}

export const getUser = () => {
    return AxiosClient.get(API_ENDPOINT + 'account')
}

export const Axiosregister = (data) => {
    return AxiosClient.post(API_ENDPOINT + 'register', data, {
        headers: { "Content-Type": "multipart/form-data" },
    }
    )
}

export const resetPassSendMail = (data) => {
    // console.log(data);
    return AxiosClient.post(API_ENDPOINT + 'send-reset-password?email=' + data.email)
}

export const resetPassConfirm = (data) => {
    return AxiosClient.post(API_ENDPOINT + 'reset-password', data)
}

export const getOverview = () => {
    return AxiosClient.get(API_ENDPOINT + 'overview')
}

export const refeshToken = (token) => {
    return AxiosClient.post(API_ENDPOINT + 'refresh-tokens', { refreshToken: token })
}