import React, { useEffect, useState } from 'react'
import { BoardWrapper } from './Board.styles'
import start from '../../../../../assets/images/start.png'
import downIcon from '../../../../../assets/images/downIcon.png'
import upIcon from '../../../../../assets/images/upIcon.png'
import arrowUp from '../../../../../assets/images/arrowUp.png'
import arrowDown from '../../../../../assets/images/arrowDown.png'
import { useDispatch, useSelector } from 'react-redux'
import { navRes } from '../../../../../store/navRes/navResSelector'
import { walletInfo } from '../../../../../services/walletServices'

function Board() {
  // format money
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  // redux logic nav Res
  const nav = useSelector(navRes);
  const [random, setRandom] = useState();
  // random board top
  const randomBoard = []
  for (let i = 1; i < 10; i++) {
    randomBoard.push(
      {
        money: 'BTC/USDT',
        price: (Math.random() * 21410.75).toFixed(2),
        persent: (Math.random() * 9.1177).toFixed(4),
        type: (Math.random() * 1).toFixed(),
      }
    )
  }
  // random board bottom
  const randomBoardBottom = []
  const date = new Date();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const secon = date.getSeconds();

  for (let i = 1; i < 8; i++) {
    randomBoardBottom.push(
      {
        time: `${hour}:${minutes < 9 ? '0' : ''}${minutes - 1}:${secon + i >= 60 ? 0 + i : secon + i}`,
        money: 'BTC/USDT',
        price: (Math.random() * 110.75259).toFixed(4),
        type: (Math.random() * 1).toFixed(),
      }
    )
  }

  useEffect(() => {
    setInterval(() => {
      setRandom(Math.random())
    }, 1500);
  }, [])
  // console.log(randomBoard);
  return (
    <BoardWrapper>
      {/* header */}
      <BoardWrapper.Header>
        <BoardWrapper.StartIcon src={start} />
        <span>BTC / </span> <span>BUSD</span>
      </BoardWrapper.Header>
      <div className='h-[calc(100%-theme(spacing.header))] flex flex-col gap-[5px] max-md:gap-[0]'>
        {/* Thị trường */}
        <BoardWrapper.market className={nav == 3 ? 'max-md:flex' : 'max-md:hidden'}>
          <BoardWrapper.Title>Thị trường</BoardWrapper.Title>
          <BoardWrapper.Table>
            {/* header */}
            <thead>
              <BoardWrapper.TrHead>
                <BoardWrapper.Th>Loại tiền</BoardWrapper.Th>
                <BoardWrapper.Th>Tỉ giá</BoardWrapper.Th>
                <BoardWrapper.Th>Phần trăm</BoardWrapper.Th>
              </BoardWrapper.TrHead>
            </thead>
            {/* item */}
            <tbody>
              {
                randomBoard.map((item, i) => (
                  <BoardWrapper.Tr key={i} className={item.type == 0 ? 'bg-[rgba(255,107,111,0.3)]' : 'bg-[rgba(36,255,74,0.3)]'}>
                    <BoardWrapper.Td>{item.money}</BoardWrapper.Td>
                    <BoardWrapper.Td>{item.price}</BoardWrapper.Td>
                    <BoardWrapper.Td>
                      <BoardWrapper.Percent>
                        <img className='w-[10px] h-[6px]' src={item.type == 0 ? downIcon : upIcon} />
                        <BoardWrapper.Number className={item.type == 0 ? 'text-[#FF6E75]' : ''}>{item.type == 0 ? '-' : ''}{item.persent}%</BoardWrapper.Number>
                      </BoardWrapper.Percent>
                    </BoardWrapper.Td>
                  </BoardWrapper.Tr>
                ))
              }
            </tbody>
          </BoardWrapper.Table>
        </BoardWrapper.market>
        {/* Giao dịch trực tiếp */}
        <BoardWrapper.Transaction className={nav == 2 ? 'max-md:flex' : 'max-md:hidden '}>
          <BoardWrapper.Title>Giao dịch trực tiếp</BoardWrapper.Title>
          <BoardWrapper.Table>
            {/* header */}
            <thead>
              <BoardWrapper.TrHead>
                <BoardWrapper.Th>Thời gian</BoardWrapper.Th>
                <BoardWrapper.Th>Loại tiền</BoardWrapper.Th>
                <BoardWrapper.Th>Tiền</BoardWrapper.Th>
              </BoardWrapper.TrHead>
            </thead>
            {/* item */}
            <tbody>
              {
                randomBoardBottom.map((items, i) => (
                  <BoardWrapper.Tr key={i}>
                    <BoardWrapper.Td>{items.time}</BoardWrapper.Td>
                    <BoardWrapper.Td>{items.money}</BoardWrapper.Td>
                    <BoardWrapper.Td>
                      <BoardWrapper.BoxMoney className={items.type == '0' ? 'border-[#FF6E75]' : ''}>
                        <BoardWrapper.Arrow src={items.type == '0' ? arrowDown : arrowUp} />
                        <span>{formatter.format(items.price)}</span>
                      </BoardWrapper.BoxMoney>
                    </BoardWrapper.Td>
                  </BoardWrapper.Tr>
                ))
              }
            </tbody>
          </BoardWrapper.Table>
        </BoardWrapper.Transaction>
      </div>
    </BoardWrapper>
  )
}

export default Board