import React, { useState, useEffect } from "react";
import iconSearch from "../../../../../assets/icons/icon-feather-search.png";
import chevronLeft from "../../../../../assets/icons/icon-shape-chevron-left.png";
import chevronRight from "../../../../../assets/icons/icon-shape-chevron-right.png";
import ListHistory from "./ListHistory/ListHistory";
import { History } from "./HistoryStatics.styles";
import { historyTrading } from "../../../../../services/trandingServices";

function HistoryStatics() {
  const [history, setHistory] = useState([]);
  const [historyTrade, setHistoryTrade] = useState([]);
  const [page, setPage] = useState(1)

  useEffect(() => {
    const getAPI = async () => {
      const data = await historyTrading({page: page});
      setHistory(data.data)
      setHistoryTrade(data.data.data);
    };
    getAPI();
  }, [page])
  
  const handleNext = () => {
    setPage(page + 1)
  }
  const handlePrev = () => {
      setPage(page - 1)
  }
  return (
    <History>
      <History.HeadContainer>
        <History.TextSub>Lịch sử giao dịch</History.TextSub>
        {/* Tìm kiếm giao dịch */}
        <History.InputContainer>
          <History.Img src={iconSearch}></History.Img>
          <History.InputSearch
            type="input"
            placeholder="Search"
            style={{ outline: "none" }}
            className=""
          />
        </History.InputContainer>
      </History.HeadContainer>

      <History.TableHistory>
        <History.TableTitleContainer>
          <History.TextTitleLeftTable>STT</History.TextTitleLeftTable>
          <History.TextTitleCenterTable className="max-md:pl-[20px]">
            Ngày
          </History.TextTitleCenterTable>
          <History.TextTitleCenterTable>Giao dịch</History.TextTitleCenterTable>
          <History.TextTitleCenterTable>Lợi nhuận</History.TextTitleCenterTable>
          <History.TextTitleRightTable>Đầu tư</History.TextTitleRightTable>
        </History.TableTitleContainer>

        {/* Render lịch sử giao dịch */}
        {historyTrade.map((item, index) => (
          <ListHistory item={item} stt={index} key={index} page={page} />
        ))}

        <History.ChangePageContainer>
          <History.ButtonChangePage onClick={handlePrev} disabled={page <= 1 ? true : false}
            style={{
              backgroundImage: `url(${chevronLeft})`,
            }}
          />
          <History.TextPageNumber>{page}/{history?.meta?.pageCount}</History.TextPageNumber>
          <History.ButtonChangePage onClick={handleNext} disabled={page >= history?.meta?.pageCount ? true : false}
            style={{
              backgroundImage: `url(${chevronRight})`,
            }}
          />
        </History.ChangePageContainer>
      </History.TableHistory>
    </History>
  );
}

export default HistoryStatics;
