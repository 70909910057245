import React, { useEffect, useState, useRef } from 'react'
import coins from '../../../../../assets/images/coins.png'
import { BoardWrapper } from './BoardControl.styles'
import up from '../../../../../assets/images/up.png'
import down from '../../../../../assets/images/down.png'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { navRes } from '../../../../../store/navRes/navResSelector'
import { changeNav } from '../../../../../store/navRes/navResSlice'
import { walletInfo } from '../../../../../services/walletServices'
import { SetCount, setValue } from '../../../../../store/value/valueSlice'
import { cancelTrading, historyTran, result, trading } from '../../../../../services/trandingServices'
import { changeType, reRender } from '../../../../../store/transaction/transaction'
import Notiflix from 'notiflix'
import { format } from "date-fns";
import io from 'socket.io-client';
import close from '../../../../../assets/images/close.png'
import win from '../../../../../assets/images/win.png'
import lose from '../../../../../assets/images/lose.png'
import { sesstionHistory } from '../../../../../services/sessionServices'
import axios from 'axios'

function BoardControl() {
  const userInfo = useSelector(state => state.auth.user)
  const [idTrans, setIdTrans] = useState();
  const [history, setHistory] = useState([])
  const [count, setCount] = useState(null);
  const [status, setstatus] = useState();
  const [pathOne, setPathOne] = useState([])
  const [pathTwo, setPathTwo] = useState([])
  const [pathThree, setPathThree] = useState([])
  const [pathFour, setPathFour] = useState([])
  const [pathFive, setPathFive] = useState([])
  const [id, setId] = useState([])
  const [results, setResults] = useState()

//   const BOT_TOKEN = '7714924039:AAE7qDbRN5oLcx7VPlpFrU4JA4G9WLmDD3c'; // Thay thế bằng token của bạn
//   const CHAT_ID = '6265089133'; // Thay thế bằng chat ID bạn muốn gửi tin nhắn đến
//   const url = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`;
  const list = []
  for (var i = 0; i < 20; i++) {
    list.push(i)
  }
  // format money
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // console.log(formatter.format(2500));
  // redux logic nav Res
  const dispatch = useDispatch();
  const nav = useSelector(navRes)
  const [wallet, setWallet] = useState()

  // get ví tiền
  const getMoney = () => {
    walletInfo().then(res => {
      setWallet(res.data.money);
    })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    getMoney()
  }, [])
  // get value store
  const valueNumber = useSelector(state => state.value.value);
  // get type transaction
  const typeTran = useSelector(state => state.transaction.type);
  // sự kiện mua hoặc bán
  const handleTrading = (type) => {

    dispatch(changeType(type))
    trading({
      positionType: type,
      amount: valueNumber
    })
      .then(res => {
        Notiflix.Notify.success('Đặt lệnh thành công')
        var status = type === "BUY" ? "BUY 🟢" : "SELL 🔴"
        setIdTrans(res.data.id)

        getMoney()
        dispatch(reRender(Math.random()))
        // axios.post(url, {
        //   chat_id: CHAT_ID,
        //   text: `<b>Website</b>: ${window.location.href}\n<b>Tài khoản</b>: ${userInfo.email}\n<b>Nội dung</b>: Tạo Giao Dịch Thành Công\n<b>Loại giao dịch</b>: ${status}\n<b>Số tiền đặt lệnh</b>: ${valueNumber} USD\n<b>Số tiền trước GD</b>: ${wallet} USD`,
        //   parse_mode: "html"
        // });
      })
      .catch(err => {
        console.log(err);
        if (err.response.data.code == "NOT_ENOUGH_MONEY") {
          Notiflix.Notify.failure('Bạn không đủ tiền để cược')
        }
        if (err.response.data.code == "WAIT_RESULT") {
          Notiflix.Notify.failure('Bạn đã đặt cược trước đó, vui lòng đợi kết quả')
        }
      })
  }
  // sự kiện cancel tranding
  const handleCancel = () => {
    cancelTrading(idTrans)
      .then(() => {
        Notiflix.Notify.success('Huỷ giao dịch thành công')
        dispatch(changeType('FREE'))
        getMoney()
        dispatch(reRender(Math.random()))
        // axios.post(url, {
        //   chat_id: CHAT_ID,
        //   text: `<b>Tài khoản</b>: ${userInfo.email}\n<b>Nội dung</b>: Huỷ giao dịch\n<b>Số tiền đặt lệnh</b>: ${valueNumber} USD\n<b>Số tiền trước khi huỷ GD</b>: ${wallet} USD`,
        //   parse_mode: "html"
        // });
      })
      .catch((err) => {
        Notiflix.Notify.failure('Huỷ không thành công')
      })
  }
  // get history trading 
  const historyTrading = () => {
    historyTran()
      .then(res => {
        setHistory(res.data.data);
      })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    historyTrading()
  }, [])

  // kiểm tra type transaction
  const checkType = (type) => {
    switch (type) {
      case 'BUY':
        return '#23FF49'
      case 'SELL':
        return '#F03D46'
      default:
        return '#8FA8D8'
    }
  }
  // đếm ngược
  useEffect(() => {
    const socket = io('https://test.bocryptos.com');
    socket.on('message', (data) => {
      setId(data.id);
      setCount(data.counter);
      dispatch(SetCount(data.counter))
    });
  }, [])
  // set notication
  const notication = useRef();
  // lấy kết quả trading
  if (count == 59) {
    dispatch(changeType('FREE'))
    result()
      .then(res => {
        if (typeTran !== 'FREE') {
          notication.current.style.display = 'flex'
          setstatus(res.data)
          if (res.data) {
            // axios.post(url, {
            //   chat_id: CHAT_ID,
            //   text: `<b>Website</b>: ${window.location.href}\n<b>Tài khoản</b>: ${res.data.user.email}\n<b>Thời gian tạo</b>: ${res.data.createdAt}\n<b>Thời gian cập nhật</b>: ${res.data.updatedAt}\n<b>Nội dung</b>: Trả thưởng giao dịch\n<b>Trạng thái</b>: ${res.data.status}\n<b>ID Giao dịch</b>: ${res.data.session}\n<b>Số tiền đặt lệnh</b>: ${res.data.amount} USD\n<b>Số tiền cộng/trừ</b>: ${res.data.profit} USD\n`,
            //   parse_mode: "html"
            // });
            // console.log(res.data);
          }

          setTimeout(() => {
            notication.current.style.display = 'none'
          }, 3000);
          getMoney()
          historyTrading()
        }
      })
      .catch(err => {
        console.log(err);
      })
  }
  // handle change value
  const handleChangeValue = (e) => {
    const value = e.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
    dispatch(setValue(value));
  }
  // get session history
  const getHistory = () => {
    sesstionHistory()
      .then(res => {
        setResults(res.data)
        setPathOne(res.data.pathOne);
        setPathTwo(res.data.pathTwo);
        setPathThree(res.data.pathThree);
        setPathFour(res.data.pathFour);
        setPathFive(res.data.pathFive);
      })
      .catch(err => {
        console.log(err);
      })
  }
  // custom path
  const customPathOne = []
  const customPathTwo = []
  const customPathThree = []
  const customPathFour = []
  const customPathFive = []

  for (var i = 0; i < 20; ++i) {
    if (pathOne[i]) {
      customPathOne.push(pathOne[i])
    } else {
      customPathOne.push({ result: 'undefined' })
    }
    // 2
    if (pathTwo[i]) {
      customPathTwo.push(pathTwo[i])
    } else {
      customPathTwo.push({ result: 'undefined' })
    }
    // 2
    if (pathThree[i]) {
      customPathThree.push(pathThree[i])
    } else {
      customPathThree.push({ result: 'undefined' })
    }
    // 2
    if (pathFour[i]) {
      customPathFour.push(pathFour[i])
    } else {
      customPathFour.push({ result: 'undefined' })
    }
    // 2
    if (pathFive[i]) {
      customPathFive.push(pathFive[i])
    } else {
      customPathFive.push({ result: 'undefined' })
    }
  }
  useEffect(() => {
    getHistory()
  }, [])

  if (count == 59) {
    getHistory()
  }

  // const customPathOne = []
  // for (var i = 0; i < 20; ++i) {
  //   if (pathOne[i]) {
  //     customPathOne.push(pathOne[i])
  //   } else {
  //     customPathOne.push({ result: 'undefined' })
  //   }
  // }

  const checkColor = (bi) => {
    switch (bi?.result) {
      case 'SELL':
        return 'bg-[#FF6E75]'
      case 'undefined':
        return 'bg-[#E9E7E7]'
      default:
        return 'bg-[#23FF49]'
    }
  }

  return (
    <BoardWrapper>
      <BoardWrapper.Header>
        <BoardWrapper.HeaderTitle>Ví của bạn</BoardWrapper.HeaderTitle>
        <BoardWrapper.HeaderBoxCoin>
          <img className='w-[25px] h-[25px]' src={coins} />
          <span className='text-[#fff]'>{formatter.format(wallet)}</span>
        </BoardWrapper.HeaderBoxCoin>
      </BoardWrapper.Header>
      <BoardWrapper.Body>
        <BoardWrapper.BoxTop>
          <div>
            {/* place order */}
            <BoardWrapper.Place>
              <span className='font-[500]'>PLACE ORDER</span>
              <span className='text-[#465991]'>BTC / BUSD</span>
            </BoardWrapper.Place>
            {/* ID */}
            <BoardWrapper.ID>
              <span>ID:</span>
              <span>{id}</span>
            </BoardWrapper.ID>
          </div>
          {/* giá trị */}
          <BoardWrapper.Value>
            <span className='text-[#fff]'>Giá trị</span>
            <BoardWrapper.ValueBoxres>
              <BoardWrapper.BoxValue>
                <BoardWrapper.BoxValueLeft>
                  <div className={`w-[22px] h-[22px] rounded-[50%] bg-[${checkType(typeTran)}]`}></div>
                </BoardWrapper.BoxValueLeft>
                <BoardWrapper.BoxValueCenter>
                  <BoardWrapper.BoxValueNumber>
                    <BoardWrapper.BoxValueInput
                      type="text"
                      onChange={(e) => handleChangeValue(e.target.value)}
                      value={valueNumber}
                    />
                  </BoardWrapper.BoxValueNumber>
                </BoardWrapper.BoxValueCenter>
                <BoardWrapper.BoxValueRight>
                  <span className='text-[#B9D2FF]'>USD</span>
                </BoardWrapper.BoxValueRight>
              </BoardWrapper.BoxValue>
              {/* item when responsive start */}
              <BoardWrapper.WrapperCountres>
                <BoardWrapper.Countdownres>{count}s</BoardWrapper.Countdownres>
              </BoardWrapper.WrapperCountres>
              {/* item when responsive start */}
            </BoardWrapper.ValueBoxres>
            {/* option value */}
            <BoardWrapper.BoxValueOption>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 5 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(5))}
              >
                +5
              </BoardWrapper.BoxValueOptionItem>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 10 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(10))}
              >
                +10
              </BoardWrapper.BoxValueOptionItem>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 15 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(15))}
              >
                +15
              </BoardWrapper.BoxValueOptionItem>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 20 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(20))}
              >
                +20
              </BoardWrapper.BoxValueOptionItem>
              <BoardWrapper.BoxValueOptionItem
                className={valueNumber == 25 ? 'bg-[#080B32] text-[#fff]' : ''}
                onClick={() => dispatch(setValue(25))}
              >
                +25
              </BoardWrapper.BoxValueOptionItem>
            </BoardWrapper.BoxValueOption>
            {/* bảng điều khiển */}
            <BoardWrapper.Control>
              <BoardWrapper.WrapperCount>
                <BoardWrapper.Countdown>{count}s</BoardWrapper.Countdown>
                <span >Thời gian còn lại</span>
              </BoardWrapper.WrapperCount>
              <div className='flex gap-[10px] w-full'>
                <BoardWrapper.BtnUP
                  onClick={() => handleTrading('BUY')}
                  disabled={typeTran !== "FREE" || count <= '10' || valueNumber <= 0 || wallet <= 0 ? true : false}
                  className={typeTran !== "FREE" || count <= '10' || valueNumber <= 0 || wallet <= 0 ? 'opacity-40 cursor-default' : ''}
                >
                  <span>Buy</span>
                  <img className='w-[30px] h-[18px]' src={up} />
                </BoardWrapper.BtnUP>
                <BoardWrapper.BtnDown
                  onClick={() => handleTrading('SELL')}
                  disabled={typeTran !== "FREE" || count <= '10' || valueNumber <= 0 || wallet <= 0 ? true : false}
                  className={typeTran !== "FREE" || count <= '10' || valueNumber <= 0 || wallet <= 0 ? 'opacity-40 cursor-default' : ''}
                >
                  <span>Sell</span>
                  <img className='w-[30px] h-[18px]' src={down} />
                </BoardWrapper.BtnDown>
                {/* btnclose when responsive start */}
                <BoardWrapper.BoxCloseres
                  onClick={handleCancel}
                  disabled={typeTran == "FREE" || count <= '10' ? true : false}
                  className={typeTran == "FREE" || count <= '10' ? 'opacity-40 cursor-default' : ''}
                >
                  <AiOutlineCloseCircle className='text-[29px] text-[#fff]' />
                </BoardWrapper.BoxCloseres>
                {/* btnclose when responsive end */}
              </div>
              <BoardWrapper.BtnCancel
                onClick={handleCancel}
                disabled={typeTran == "FREE" || count <= '10' ? true : false}
                className={typeTran == "FREE" || count <= '10' ? 'opacity-40 cursor-default' : ''}
              >Huỷ giao dịch</BoardWrapper.BtnCancel>
            </BoardWrapper.Control>
          </BoardWrapper.Value>
          {/* history when responsive start */}
          {/* lịch sử phiên */}
          <BoardWrapper.History>
            {/* header */}
            <BoardWrapper.HistoryHead>
              <span className='text-[#B9D2FF]'>100 kết quả mới</span>
              <BoardWrapper.HistoryUp>
                <span>Tăng</span>
                <span className='text-[#fff]'>{results?.countBuy}</span>
              </BoardWrapper.HistoryUp>
              <BoardWrapper.HistoryDown>
                <span>Giảm</span>
                <span className='text-[#fff]'>{results?.countSell}</span>
              </BoardWrapper.HistoryDown>
            </BoardWrapper.HistoryHead>
            {/* body */}
            <div className='overflow-x-scroll w-screen'>
              <BoardWrapper.HistoryBiList>
                <BoardWrapper.HistoryBi>
                  {
                    customPathFive.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
                <BoardWrapper.HistoryBi>
                  {
                    customPathFour.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
                <BoardWrapper.HistoryBi>
                  {
                    customPathThree.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
                <BoardWrapper.HistoryBi>
                  {
                    customPathTwo.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
                <BoardWrapper.HistoryBi>
                  {
                    customPathOne.reverse().map((bi, i) => (
                      <BoardWrapper.HistoryBiItem key={i} className={checkColor(bi)}></BoardWrapper.HistoryBiItem>
                    ))
                  }
                </BoardWrapper.HistoryBi>
              </BoardWrapper.HistoryBiList>
            </div>
            <BoardWrapper.Historypage>
              <div className='w-[13px] h-[11px] rounded-[2px] bg-[#B9D2FF]'></div>
              <div className='w-[13px] h-[11px] rounded-[2px] bg-[#21286B]'></div>
              <div className='w-[13px] h-[11px] rounded-[2px] bg-[#21286B]'></div>
            </BoardWrapper.Historypage>
          </BoardWrapper.History>
          {/* history when responsive end */}
          {/* nav when responsive start */}
          <BoardWrapper.Navbar>
            <BoardWrapper.NavItem
              className={nav == 1 ? "bg-gradient-to-r from-[#00B7AE] to-[#0353BB]" : ''}
              onClick={() => dispatch(changeNav(1))}
            >
              Giao dịch của bạn
            </BoardWrapper.NavItem>
            <BoardWrapper.NavItem
              className={nav == 2 ? "bg-gradient-to-r from-[#00B7AE] to-[#0353BB]" : ''}
              onClick={() => dispatch(changeNav(2))}
            >
              Giao dịch trực tiếp
            </BoardWrapper.NavItem>
            <BoardWrapper.NavItem
              className={nav == 3 ? "bg-gradient-to-r from-[#00B7AE] to-[#0353BB]" : ''}
              onClick={() => dispatch(changeNav(3))}
            >
              Tiền tệ
            </BoardWrapper.NavItem>
          </BoardWrapper.Navbar>
          {/* nav when responsive end */}
          {/* notication */}
          <BoardWrapper.Notication ref={notication}>
            <BoardWrapper.NoticationBtn src={close} />
            <BoardWrapper.NoticationIcon src={status?.status == "LOSE" ? lose : win} />
            <BoardWrapper.NoticationValue className={status?.status == "LOSE" ? 'text-[#FF6E75]' : 'text-[#23FF49]'}>{status?.status == "LOSE" ? '-' : '+'} {formatter.format(status?.profit)}</BoardWrapper.NoticationValue>
          </BoardWrapper.Notication>
        </BoardWrapper.BoxTop>
        <BoardWrapper.BoxBottom className={nav == 1 ? 'max-md:flex' : 'max-md:hidden'}>
          <BoardWrapper.BoxBottomTitle>Giao dịch của bạn</BoardWrapper.BoxBottomTitle>
          <BoardWrapper.Table>
            <thead>
              <BoardWrapper.TrHead>
                <BoardWrapper.Th className="pl-[10px] max-md:pl-[15px]">
                  <BoardWrapper.Bi></BoardWrapper.Bi>
                </BoardWrapper.Th>
                <BoardWrapper.Th>Thời gian</BoardWrapper.Th>
                <BoardWrapper.Th>Loại tiền</BoardWrapper.Th>
                <BoardWrapper.Th className="pr-[10px] max-md:pl-[15px]">Tiền</BoardWrapper.Th>
              </BoardWrapper.TrHead>
            </thead>
            <tbody>
              {
                history.map((item, i) => (
                  <BoardWrapper.Tr key={i}>
                    <BoardWrapper.Td className="pl-[10px] max-md:pl-[15px]">
                      {
                        item.position == "BUY" ?
                          <BoardWrapper.BiGreen></BoardWrapper.BiGreen> :
                          <BoardWrapper.BiRed></BoardWrapper.BiRed>
                      }
                    </BoardWrapper.Td>
                    <BoardWrapper.Td>{format(new Date(item.createdAt), "dd/MM/yy")}</BoardWrapper.Td>
                    <BoardWrapper.Td>USD/BTC</BoardWrapper.Td>
                    <BoardWrapper.Td className={item.status == 'LOSE' ? 'text-[#FF6E75] pl-[10px] max-md:pl-[15px]' : 'text-[#23FF49] pl-[10px] max-md:pl-[15px]'}>{item.status == 'LOSE' ? '-' : '+'}{formatter.format(item.profit)}</BoardWrapper.Td>
                  </BoardWrapper.Tr>
                ))
              }
            </tbody>
          </BoardWrapper.Table>
        </BoardWrapper.BoxBottom>
      </BoardWrapper.Body>
    </BoardWrapper>
  )
}

export default BoardControl