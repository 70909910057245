import React, { useState, useEffect } from "react";
import { Stats } from "./Statistics.styles";
import RateWinLose from "./components/RateWinLose/RateWinLose";
import TransOverview from "./components/TransOverview/TransOverview";
import HistoryStatics from "./components/HistoryStatics/HistoryStatics";
import ReportStatics from "./components/ReportStatics/ReportStatics";
import Header from './../../../components/header/Header';
import { getOverview } from "../../../services/authServices";

function Statistics() {
  const [historyBet, SetHistoryBet] = useState([]);

  useEffect(() => {
    const getAPI = async () => {
      const data = await getOverview();
      SetHistoryBet(data.data)
    };
    getAPI();
  }, [])
  return (
    <Stats>
      <Header title="thống kê số liệu"/>
      <Stats.Body>
        <RateWinLose data={historyBet}/>
        <TransOverview data={historyBet}/>
        <Stats.TransactionStatistics>
          <HistoryStatics />
          <ReportStatics data={historyBet}/>
        </Stats.TransactionStatistics>
      </Stats.Body>
    </Stats>
  );
}

export default Statistics;
