import React from "react";
import bgLogin from "../../assets/images/bgLogin.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { resetPassConfirm } from "../../services/authServices";
import { useForm } from "react-hook-form";
import Notiflix from 'notiflix';

function EnterPassword() {
  let navigate = useNavigate();
  const email = useSelector(state => state.resetPass.email);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => {
    console.log(data);
    resetPassConfirm({
      email: email,
      password: data.password,
      otp: data.otp
    })
      .then((res) => {
        Notiflix.Notify.success('Đổi mật khẩu thành công');
        navigate('/')
      })
      .catch((err) => {
        Notiflix.Notify.failure('otp không phù hợp');
      })
  }
  return (
    <div className="container flex h-screen max-md:flex-col">
      <div className="w-5/12 h-full flex items-center p-[30px] max-md:w-full max-md:p-0 max-md:relative max-md:top-[-200px]">
        <img src={bgLogin} className="h-full flex aspect-[700/1020]" alt="" />
      </div>

      <div className="w-7/12 items-center flex flex-col justify-center items-center max-md:w-full max-md:p-[15px] max-md:relative max-md:top-[-450px] max-md:z-[10] max-md:bg-[#fff] max-md:rounded-xl">
        <span className="text-3xl font-bold text-[#000000] my-[90px]">
          Quên mật khẩu
        </span>

        <div className="w-6/12 max-md:w-[85%]">
          <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <label className="w-full flex flex-col">
              <span className="text-lg font-semibold">Nhập mã xác nhận</span>
              <span className="text-md font-normal text-[#6C6C6C] mb-[10px]">
                Mã xác nhận sẽ được gửi về Email của bạn
              </span>
              <div className="h-[60px] flex flex-row items-center border-2 border-solid rounded-md border-[#BCBCBC]">
                <input
                  {...register("otp", {
                    required: true,
                  })}
                  className="w-full flex flex1 outline-0 px-[20px]"
                />
              </div>
              {errors?.otp?.type === "required" && <p className="text-[#FF0000]">Vui lòng nhập mã đã gửi</p>}
            </label>

            <label className="w-full flex flex-col">
              <span className="text-lg font-semibold mt-[25px] mb-[10px]">
                Nhập mật khẩu mới
              </span>
              <div className="h-[60px] flex flex-row items-center border-2 border-solid rounded-md border-[#BCBCBC]">
                <input
                  {...register("password", {
                    required: true,
                  })}
                  className="w-full flex flex1 outline-0 px-[20px]"
                />
              </div>
              {errors?.password?.type === "required" && <p className="text-[#FF0000]">Vui lòng nhập mật khẩu mới</p>}
            </label>

            <button
              className="w-full h-[60px] text-lg text-[#FFFFFF] rounded-md bg-gradient-to-r from-[#18FFBA] to-[#1C99FF] mt-[50px]"
              type="submit"
            >
              Đổi mật khẩu
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EnterPassword;
