import React from "react";
import item1 from "../../../../../assets/images/panel-item1-blue.png";
import item2 from "../../../../../assets/images/panel-item2-green.png";
import item3 from "../../../../../assets/images/panel-item3-yellow.png";
import item4 from "../../../../../assets/images/panel-item4-red.png";
import icon1 from "../../../../../assets/icons/icon-transaction.png";
import icon2 from "../../../../../assets/icons/icon-gain.png";
import icon3 from "../../../../../assets/icons/icon-scales.png";
import icon4 from "../../../../../assets/icons/icon-income.png";
import bgRevenue from "../../../../../assets/images/panel-item5-skyblue.png";
import iconRevenue from "../../../../../assets/icons/icon-awesome-money-bill.png";
import { Transaction } from "./TransOverview.styles";

function TransOverview(data) {
  return (
    <Transaction>
      <Transaction.ItemContainer>
        {/* thống kê các giao dịch */}
        <Transaction.Item style={{ backgroundImage: `url(${item1})` }}>
          <Transaction.Group>
            <Transaction.Icon src={icon1} />
            <Transaction.TextSub>Lượt giao dịch</Transaction.TextSub>
          </Transaction.Group>
          <Transaction.TextItem>{data.data?.count}</Transaction.TextItem>
        </Transaction.Item>
        <Transaction.Item style={{ backgroundImage: `url(${item2})` }}>
          <Transaction.Group>
            <Transaction.Icon src={icon2} />
            <Transaction.TextSub>Tổng vòng thắng</Transaction.TextSub>
          </Transaction.Group>
          <Transaction.TextItem>{data.data?.countWin}</Transaction.TextItem>
        </Transaction.Item>
        <Transaction.Item style={{ backgroundImage: `url(${item3})` }}>
          <Transaction.Group>
            <Transaction.Icon src={icon3} />
            <Transaction.TextSub>Tổng vòng hòa</Transaction.TextSub>
          </Transaction.Group>
          {/* chưa có dữ liệu API Tổng vòng hòa*/}
          <Transaction.TextItem>0</Transaction.TextItem>
        </Transaction.Item>
        <Transaction.Item style={{ backgroundImage: `url(${item4})` }}>
          <Transaction.Group>
            <Transaction.Icon src={icon4} />
            <Transaction.TextSub>Tổng vòng thua</Transaction.TextSub>
          </Transaction.Group>
          <Transaction.TextItem>{data.data?.countLose}</Transaction.TextItem>
        </Transaction.Item>
      </Transaction.ItemContainer>

      {/* Tổng doanh thu */}
      <Transaction.RevenueContainer>
        <Transaction.Revenue
          style={{
            backgroundImage: `url(${bgRevenue})`,
          }}
        >
          <Transaction.Group>
            <Transaction.IconRevenue src={iconRevenue} />
            <Transaction.TextSubRevenue>
              Tổng doanh thu
            </Transaction.TextSubRevenue>
          </Transaction.Group>
          <Transaction.TextRevenue>${Number(data.data?.totalWinAmount)?.toFixed(1)}</Transaction.TextRevenue>
        </Transaction.Revenue>
      </Transaction.RevenueContainer>
    </Transaction>
  );
}

export default TransOverview;
