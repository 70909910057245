import React from "react";
import bgLogin from "../../assets/images/bgLogin.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { resetPassSendMail } from "../../services/authServices";
import { useDispatch } from 'react-redux';
import { confirmMail } from "../../store/resetpass/resetpassSlice";
import Notiflix from "notiflix";

function ForgotPassword() {
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = data => {
    // Notiflix.Loading.pulse();
    resetPassSendMail(data)
      .then((res) => {
        dispatch(confirmMail(data.email))
        Notiflix.Loading.remove();
        navigate("/enterPassword")
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code == "USER_NOT_FOUND") {
          Notiflix.Notify.failure('Email chưa được đăng ký')
        } else {
          Notiflix.Notify.failure('Email không tồn tại')
        }
      })
  };
  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto w-[150px]" src="http://nextbo.co/wp-content/uploads/2023/04/logo500x500.png" alt="NextBO" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Lấy Lại Mật Khẩu</h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">Email</label>
            <div className="mt-2">
              <input
                {
                ...register('email', {
                  required: true,
                  pattern: /^\S+@\S+\.\S+$/
                })
                }
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
            {errors?.email?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập tài khoản</p>}
            {errors?.email?.type === "pattern" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập đúng định dạng email</p>}
          </div>

          <div>
            <button type="submit" className="flex w-full justify-center rounded-md bg-[#1457ed] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#1565f1] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Lấy mã xác nhận</button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          <a href="#" className="font-semibold leading-6 text-[#1457ed] hover:text-[#1565f1]" onClick={() => navigate("/")}>Đăng Nhập</a>
        </p>
      </div>
    </div>
  );
}

export default ForgotPassword;
