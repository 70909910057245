import tw from "tailwind-styled-components";

export const Transaction = tw.div`
    w-full
    flex
    flex-row
    max-md:flex-col
`;
Transaction.ItemContainer = tw.div`
    w-8/12
    grid  
    gap-[40px]
    grid-cols-4

    max-md:w-full
    max-md:gap-x-[15px]
    max-md:gap-y-[20px]
    max-md:grid
    max-md:grid-cols-2
`;
Transaction.Item = tw.div`
    bg-center
    bg-cover
    rounded-lg
    pl-[30px]
    min-w-[180px]

    max-md:pl-[15px]
`;
Transaction.Group = tw.div`
    flex
    justify-between
    pr-5
`;
Transaction.Icon = tw.img`
    flex 
    w-[30px]
    mt-[15px]

    max-md:mt-[20px]
    max-md:h-1/3
`;
Transaction.TextSub = tw.span`
    flex 
    text-xl 
    font-light 
    text-[#fff] 
    text-opacity-70 
    mt-[25px] 
  
    items-center
    md:text-lg
    xl:text-xl
    max-md:text-base
    max-md:mt-[15px] 
`;
Transaction.TextItem = tw.span`
    flex 
    text-2xl
    font-bold
    text-[#fff]
    mt-[10px]

    xl:mt-[15px]
    xl:text-3xl
    2xl:mt-[25px]
    2xl:text-4xl
    desktop:mt-[20px]
    max-md:mt-[10px]
`;

Transaction.RevenueContainer = tw.div`
    w-4/12
    flex
    flex-col
    justify-items-end
    items-end
    h-full

    max-md:w-full
    max-md:my-[25px]
`;
Transaction.Revenue = tw.div`
    h-full
    w-10/12
    bg-cover 
    rounded-lg 
    px-[25px]
    min-w-[350px]

    max-md:w-full
`;
Transaction.IconRevenue = tw.img`
    flex 
    h-[35px] 
    mt-[30px]
`;
Transaction.TextSubRevenue = tw.span`
    flex 
    text-2xl 
    font-normal 
    text-[#fff] 
    text-opacity-70 
    mt-[30px] 

    xl:text-3xl 
    xl:text-3xl 
    desktop:text-3xl

    max-md:text-3xl
`;
Transaction.TextRevenue = tw.span`
    flex 
    text-3xl 
    font-bold 
    text-[#fff] 
    mt-[15px]
    mb-[15px]
    lg:text-3xl 
    xl:mt-[20px]
    xl:text-3xl
    2xl:text-4xl
    desktop:text-3xl
    desktop:mt-[15px]

    max-md:text-4xl
    max-md:my-[20px]
`;