import tw from 'tailwind-styled-components';

export const NavWapper = tw.div`
    flex
    flex-col
    h-h-full
    justify-between
    bg-[#02142b]
    relative

    max-md:fixed
    max-md:top-0
    max-md:right-[-100%]
    max-md:bottom-0
    max-md:w-[80%]
    max-md:rounded-l-[20px]
    max-md:justify-start
    max-md:height-full
    max-md:z-50
`
NavWapper.Open = tw.div`
    flex
    flex-col
    h-screen
    justify-between
    bg-[#17254E]
    relative

    max-md:fixed
    max-md:top-0
    max-md:right-0
    max-md:bottom-0
    max-md:w-[80%]
    max-md:rounded-l-[20px]
    max-md:justify-start
    max-md:height-full
    max-md:z-50
`
NavWapper.IconClose = tw.img`
    md:hidden

    max-md:w-[30px]
    max-md:h-[30px]
    max-md:top-[30px]
    max-md:right-[30px]
    max-md:absolute
    max-md:z-10
`
NavWapper.Ul = tw.ul`
    flex
    flex-col
    justify-center
    items-center

    max-md:p-[30px]
    relative
    after:content-['']
    after:w-[80%]
    after:h-[1px]
    after:bg-[#ccc]
    after:absolute
    after:bottom-[0px]
`
NavWapper.Li = tw.li`
    w-full
    text-[14px]
    flex
    flex-col
    justify-center
    items-center
    gap-[10px]
    h-[90px]
    text-[#fff]
    font-light
    cursor-pointer
    relative
    after:content-['']
    after:w-full
    after:h-[1px]
    after:bg-[#ccc]
    after:absolute
    after:bottom-[0px]
    
    max-md:flex-row
    max-md:justify-start
    max-md:h-[70px]
    max-md:bg-transparent
    max-md:after:h-0
`
NavWapper.Img = tw.img`
    w-[20px]
    h-[20px]

    max-md:w-[30px]
    max-md:h-[30px]
`
NavWapper.Span = tw.span`
    max-md:text-[18px]
`
NavWapper.Out = tw.div`
    flex
    text-[14px]
    flex-col
    justify-center
    items-center
    gap-[10px]
    py-[20px]
    text-[#fff]
    cursor-pointer
    font-light

    max-md:flex-row
    max-md:justify-start
    max-md:p-[30px]
    max-md:ml-[7px]
`