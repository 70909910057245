import React, { useEffect, useState } from 'react';
import { Charts } from './Chart.styles';
import slide from '../../../../../assets/images/slide.png'
import chart from '../../../../../assets/images/chart.png'
import coins from '../../../../../assets/images/coins.png'
import menu from '../../../../../assets/images/menu.png'
import { useDispatch, useSelector } from 'react-redux'
import { changeIsOpen } from '../../../../../store/navRes/navResSlice';
import { walletInfo } from '../../../../../services/walletServices';
import { sesstionHistory } from '../../../../../services/sessionServices';
import styled from 'styled-components'
import CandleChart from './CandleChart';
import { setvalueNen } from '../../../../../store/nen/nenSlice';
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

function Chart() {
  const [wallet, setWallet] = useState()
  const dispatch = useDispatch()
  const [pathOne, setPathOne] = useState([])
  const [pathTwo, setPathTwo] = useState([])
  const [pathThree, setPathThree] = useState([])
  const [pathFour, setPathFour] = useState([])
  const [pathFive, setPathFive] = useState([])
  const [result, setResult] = useState()

  const reRender = useSelector(state => state.transaction.reRender);

  // format money
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // get ví 
  const getMoney = () => {
    walletInfo().then(res => {
      setWallet(res.data.money);
    })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    getMoney()
  }, [reRender])
  // get count
  const count = useSelector(state => state.value.count);
  // get session history
  const getHistory = () => {
    sesstionHistory()
      .then(res => {
        setResult(res.data)
        setPathOne(res.data.pathOne);
        setPathTwo(res.data.pathTwo);
        setPathThree(res.data.pathThree);
        setPathFour(res.data.pathFour);
        setPathFive(res.data.pathFive);
        dispatch(setvalueNen(res.data.pathOne[0].result))
      })
      .catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
    getHistory()
  }, [])

  if (count == 59) {
    getHistory()
    walletInfo().then(res => {
      setWallet(res.data.money);
    })
      .catch(err => {
        console.log(err);
      })
  }

  const customPathOne = []
  const customPathTwo = []
  const customPathThree = []
  const customPathFour = []
  const customPathFive = []

  for (var i = 0; i < 20; ++i) {
    if (pathOne[i]) {
      customPathOne.push(pathOne[i])
    } else {
      customPathOne.push({ result: 'undefined' })
    }
    // 2
    if (pathTwo[i]) {
      customPathTwo.push(pathTwo[i])
    } else {
      customPathTwo.push({ result: 'undefined' })
    }
    // 2
    if (pathThree[i]) {
      customPathThree.push(pathThree[i])
    } else {
      customPathThree.push({ result: 'undefined' })
    }
    // 2
    if (pathFour[i]) {
      customPathFour.push(pathFour[i])
    } else {
      customPathFour.push({ result: 'undefined' })
    }
    // 2
    if (pathFive[i]) {
      customPathFive.push(pathFive[i])
    } else {
      customPathFive.push({ result: 'undefined' })
    }
  }

  const checkColor = (bi) => {
    switch (bi?.result) {
      case 'SELL':
        return 'bg-[#FF6E75]'
      case 'undefined':
        return 'bg-[#E9E7E7]'
      default:
        return 'bg-[#23FF49]'
    }
  }
  // handle slide
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";

  //   document.querySelector('#slide')?.appendChild(script);

  //   return () => {
  //     document.querySelector('#slide')?.removeChild(script);
  //   }
  // }, []);


  return (
    <Charts>
      {/* responsive header start */}
      {/* header title */}
      <Charts.TitleRe>
        <Charts.TitleText>BTC/BUSD</Charts.TitleText>
        <Charts.TitleIcon onClick={() => dispatch(changeIsOpen(true))} src={menu} />
      </Charts.TitleRe>
      {/* ví của bạn */}
      <Charts.HeaderRe>
        <Charts.HeaderTitleRe>Ví của bạn</Charts.HeaderTitleRe>
        <Charts.HeaderBoxCoinRe>
          <img className='w-[25px] h-[25px]' src={coins} alt='coinicon' />
          <span className='text-[#fff]'>{formatter.format(wallet)}</span>
        </Charts.HeaderBoxCoinRe>
      </Charts.HeaderRe>
      {/* responsive header end */}
      <Charts.Header id="slide">
        {/* <img className='mr-[10px]' src={slide} /> */}
        <div className="w-full h-full relative">
        <div class="livecoinwatch-widget-5" lcw-base="USD" lcw-color-tx="#999999" lcw-marquee-1="coins" lcw-marquee-2="movers" lcw-marquee-items="10" ></div>
          {/* <iframe id='frame1' className='w-full h-header flex items-center' scrolling="no" allowtransparency="true" frameBorder="0" src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22ETH%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22BTC%2FUSD%22%7D%2C%7B%22proName%22%3A%22BINANCE%3ABNBUSDT%22%2C%22title%22%3A%22BNB%2FUSDT%22%7D%2C%7B%22proName%22%3A%22BINANCE%3AADAUSD%22%2C%22title%22%3A%22ADA%2FUSD%22%7D%2C%7B%22proName%22%3A%22BINANCE%3ADOTUSDT%22%2C%22title%22%3A%22DOT%2FUSDT%22%7D%2C%7B%22proName%22%3A%22UNISWAP%3AUNIUSDT%22%2C%22title%22%3A%22UNI%2FUSDT%22%7D%5D%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22x5cz8.csb.app%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22x5cz8.csb.app%2F%22%7D"></iframe> */}
          
        </div>
      </Charts.Header>
      <Charts.Body>
        <Charts.ChartBox className="chart">
          {/* <CandleChart nen={pathOne} /> */}
          <div style={{ float: "left", width: "100%", height: "100%" }}>
            {/* <TradingViewWidget
              symbol="BINANCE:BTCUSDT" 
              interval="15"
              timezone="Etc/UTC"
              theme={Themes.DARK}
              locale="en"
              autosize
            /> */}
            {/* <iframe title="chart" src="https://www.coindesk.com/embedded-chart/mFCgDJHC7DLWg" width="100%" height="100%" frameborder="0"></iframe> */}
            {/* <iframe src="https://www.coindesk.com/embedded-chart/Nbprp8nQqmdbk" width="100%" height="100%" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" title='chart'></iframe> */}
            <iframe
              src="https://chart.bocryptos.com/"
              height="660px"
              width="100%"
              title='chart'
            />
            {/* <AdvancedRealTimeChart theme="dark" symbol="BINANCE:BTCUSDT" autosize></AdvancedRealTimeChart> */}
          </div>
        </Charts.ChartBox>
        {/* lịch sử phiên */}
        <Charts.History>
          {/* header */}
          <Charts.HistoryHead>
            <span className='text-[#B9D2FF]'>100 kết quả mới</span>
            <Charts.HistoryUp>
              <span>Tăng</span>
              <span className='text-[#fff]'>{result?.countBuy}</span>
            </Charts.HistoryUp>
            <Charts.HistoryDown>
              <span>Giảm</span>
              <span className='text-[#fff]'>{result?.countSell}</span>
            </Charts.HistoryDown>
          </Charts.HistoryHead>
          {/* body */}
          <Charts.HistoryBiList>
            <Charts.HistoryBi>
              {
                customPathFive.reverse().map((bi, i) => (
                  <Charts.HistoryBiItem key={i} className={checkColor(bi)}></Charts.HistoryBiItem>
                ))
              }
            </Charts.HistoryBi>
            <Charts.HistoryBi>
              {
                customPathFour.reverse().map((bi, i) => (
                  <Charts.HistoryBiItem key={i} className={checkColor(bi)}></Charts.HistoryBiItem>
                ))
              }
            </Charts.HistoryBi>
            <Charts.HistoryBi>
              {
                customPathThree.reverse().map((bi, i) => (
                  <Charts.HistoryBiItem key={i} className={checkColor(bi)}></Charts.HistoryBiItem>
                ))
              }
            </Charts.HistoryBi>
            <Charts.HistoryBi>
              {
                customPathTwo.reverse().map((bi, i) => (
                  <Charts.HistoryBiItem key={i} className={checkColor(bi)}></Charts.HistoryBiItem>
                ))
              }
            </Charts.HistoryBi>
            <Charts.HistoryBi>
              {
                customPathOne.reverse().map((bi, i) => (
                  <Charts.HistoryBiItem key={i} className={checkColor(bi)}></Charts.HistoryBiItem>
                ))
              }
            </Charts.HistoryBi>
          </Charts.HistoryBiList>
        </Charts.History>
      </Charts.Body>
    </Charts>
  )
}

export default Chart