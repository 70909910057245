const HEIGHT = 400;
const CANDLE_W = 10;
const D = 4;
let deltaY = 0;
let min = 0;
let max = 0;

function calcD(candle, index) {
    let posH = (max - candle.H) * deltaY;
    let posO = (max - candle.O) * deltaY;
    let posC = (max - candle.C) * deltaY;
    let posL = (max - candle.L) * deltaY;
    let posXCandle = index * (CANDLE_W + D) + CANDLE_W / 2;
    let d = `M ${posXCandle} ${posH} L ${posXCandle} ${posO} `;
    d += `M ${posXCandle - CANDLE_W / 2} ${posO} `;
    d += `L ${posXCandle + CANDLE_W / 2} ${posO} `;
    d += `L ${posXCandle + CANDLE_W / 2} ${posC} `;
    d += `L ${posXCandle - CANDLE_W / 2} ${posC} Z `;
    d += `M ${posXCandle} ${posC} L ${posXCandle} ${posL} `;
    return d;
}

export function calcPathD(candles) {
    findMinMax(candles);
    deltaY = HEIGHT / (max - min);
    let dRed = "";
    let dGreen = "";
    candles.forEach((candle, index) => {
        if (candle.O < candle.C) {
            dGreen += calcD(candle, index);
        } else {
            let candleTem = { H: candle.H, O: candle.C, C: candle.O, L: candle.L };
            dRed += calcD(candleTem, index);
        }
    });
    return { dRed, dGreen };
}

export function calcValueY(pointerY) {
    return (max - pointerY / deltaY).toFixed(2);
}

const findMinMax = (candles) => {
    min = Number.MAX_VALUE;
    max = Number.MIN_VALUE;
    candles.forEach((candle) => {
        if (max < candle.H) max = candle.H;
        if (min > candle.L) min = candle.L;
    });
};
